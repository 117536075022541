import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { Prompt } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import AddNumberForm from './AddNumberForm';
import ConfirmNumberForm from './ConfirmNumberForm';
import AddDescriptionPage from '../adddescription/AddDescriptionPage';
import ServiceHistoryPage from '../servicehistory/ServiceHistoryPage';
import AddFeaturesForm from './AddFeaturesForm';
import AddPhotoForm from './AddPhotoForm';
import AddPhotoSummaryPage from '../addphotosummary/AddPhotoSummaryPage';
import VehicleNotFound from './VehicleNotFound';
import { checkObligatoryPhotos } from '../helpers/checkObligatoryPhotos';
import { setFormStep } from '../AddVehicleActions';
import { AccountLayoutWrapper } from '../../common/components/AccountLayoutWrapper';
import errorIcon from '../../assets/img/icons/error.svg';
import {
  AddVehicleNavMapping,
  AddVehicleSteps,
  AddVehicleStepsMapping,
  AddVehicleStepsName,
} from '../../constants/AddVehicle';
import AddBasicCarDataForm from './AddBasicCarDataForm';
import AddVideoForm from './AddVideoForm';
import TyreTreadDepths from './TyreTreadDepths';
import LaunchVehicleForm from './LaunchVehicle';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';
import {
  ADD_DRAFT_VALIDATION_ERRORS,
  CLEAR_DRAFT_VALIDATION_ERRORS,
} from '../AddVehicleReducer';
import {
  validateLaunchData,
  validateServiceHistory,
  validateVehicleBasicData,
  validateVehicleDescription,
  validateVehicleFeatures,
  validateVehiclePhotos,
  validateVehicleRegistrationPlate,
  validateVehicleTyres,
} from '../helpers/draftValidator';

const AddVehicleForm = ({ form, values }) => {
  const dispatch = useDispatch();
  const vehicleFound = useSelector(state => state.addVehicle.get('vehicleFound'));
  const validationErrors = useSelector(state => state.addVehicle.get('validationErrors'));
  const formStep = useSelector(state => state.addVehicle.get('formStep'));
  const [step, setStep] = useState(formStep || 1);
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [navOptionsEnabled, setNavOptionsEnabled] = useState(true);
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const isAuctionEditing = useSelector(state => state.addVehicle.get('isAuctionEditing'));
  const [visitedSteps, setVisitedSteps] = useState(
    isAuctionEditing ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : [],
  );
  const photos = useSelector(state => state.addVehicle.get('photos'));

  const setVisitedFormStep = step => {
    if (!visitedSteps.includes(step)) {
      setVisitedSteps(prevState => {
        return [...prevState, step];
      });
    }
  };

  const prevForm = () => {
    setVisitedFormStep(step);
    if (step === 6 && !isAuctionEditing && !checkObligatoryPhotos(photos)) {
      dispatch(setFormStep(4));
    } else if (step === 5 && isAuctionEditing && checkObligatoryPhotos(photos)) {
      dispatch(setFormStep(3));
    } else {
      setStep(prevState => prevState - 1);
    }
  };

  const nextForm = forceStep => {
    setVisitedFormStep(step);
    if (forceStep) {
      setStep(forceStep);
      dispatch(setFormStep(forceStep));
      return;
    }
    if (step === 4 && !checkObligatoryPhotos(photos)) {
      setStep(6);
      dispatch(setFormStep(6));
    } else if (step === 3 && photos?.length > 0) {
      setStep(5);
      dispatch(setFormStep(5));
    } else if (step === 3 && isAuctionEditing && checkObligatoryPhotos(photos)) {
      setStep(5);
      dispatch(setFormStep(5));
    } else {
      setStep(prevState => prevState + 1);
      dispatch(setFormStep(step + 1));
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (isAuctionEditing && !isLoading) {
        setIsFormInitialized(true);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [form.values]); //

  useEffect(() => {
    if (isAuctionEditing && !isLoading && isFormInitialized) {
      dispatch({
        type: ADD_DRAFT_VALIDATION_ERRORS,
        payload: [
          {
            formStep: 1,
            errors: validateVehicleRegistrationPlate(values),
          },
          {
            formStep: 3,
            errors: validateVehicleBasicData(values),
          },
          {
            formStep: 4,
            errors: validateVehiclePhotos(values),
          },
          {
            formStep: 7,
            errors: validateVehicleTyres(values.tyreThreadDepths),
          },
          {
            formStep: 8,
            errors: validateServiceHistory(form?.values?.serviceHistory),
          },
          {
            formStep: 9,
            errors: validateVehicleFeatures(form?.values),
          },
          {
            formStep: 10,
            errors: validateVehicleDescription(form?.values?.descriptionAndSettings),
          },
          {
            formStep: 11,
            errors: validateLaunchData({
              ...form?.values?.descriptionAndSettings,
              vehicleLocation: {
                value: form?.values?.descriptionAndSettings.locationId,
              },
              timeOption: {
                value: form?.values?.descriptionAndSettings.timeOption,
              },
              type: {
                value: form?.values?.descriptionAndSettings.type,
              },
            }),
          },
        ],
      });
    }
  }, [isAuctionEditing, isLoading, isFormInitialized, form.values]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (formStep) {
      setStep(formStep);
    }
  }, [formStep]);

  useEffect(
    () => () => {
      dispatch({
        type: CLEAR_DRAFT_VALIDATION_ERRORS,
      });
    },
    [],
  );

  const stepErrorExists = currentStep => {
    return (
      validationErrors[currentStep] && !!Object.keys(validationErrors[currentStep]).length
    );
  };

  const updateServiceHistory = childFormValues => {
    form.setFieldValue('serviceHistory', {
      ...form.serviceHistory,
      ...childFormValues,
    });
  };

  const updateVehicleDescription = childFormValues => {
    form.setFieldValue('descriptionAndSettings.description', childFormValues?.description);
    form.setFieldValue(
      'descriptionAndSettings.defaultDescription',
      childFormValues?.defaultDescription,
    );
  };

  const prepareErrorMessage = el => {
    const currentFormStep = AddVehicleStepsMapping[el];
    const formStepErrors = validationErrors[currentFormStep];

    if (!formStepErrors) {
      return <div>Incomplete form step</div>;
    }

    switch (currentFormStep) {
      case 1:
      case 2:
      case 3:
      case 7:
      case 10:
      case 11:
        return (
          <>
            <b>Missing required fields:</b>
            {Object.keys(formStepErrors).map(it => (
              <div>{it}</div>
            ))}
          </>
        );
      case 4:
      case 5:
        return (
          <>
            <b>{formStepErrors}</b>
            <div>
              {formStepErrors.indexOf('photos') > -1
                ? `Minimum ${REQUIRED_PHOTOS_AMOUNT} photos required`
                : 'Some required perspectives are missing'}
            </div>
          </>
        );
      case 8:
        return typeof formStepErrors === 'object' ? (
          <>
            <b>Missing required fields:</b>
            {Object.keys(formStepErrors).map(it => (
              <div>{it}</div>
            ))}
          </>
        ) : (
          <>
            <b>Missing required fields</b>
            <div>{formStepErrors}</div>
          </>
        );
      case 9:
        return (
          <>
            <b>{formStepErrors}</b>
            <div>At least one must be selected</div>
          </>
        );
      default:
        return <div>Incomplete form step</div>;
    }
  };

  const navOptions = useCallback(() => {
    const navOrder = Object.keys(AddVehicleNavMapping);
    return navOrder.map(el => {
      const isCurrentStep =
        AddVehicleNavMapping[el] && AddVehicleNavMapping[el]?.includes(step);
      const isActive = AddVehicleNavMapping[el] === step;
      const showError =
        (isAuctionEditing || visitedSteps.includes(AddVehicleStepsMapping[el])) &&
        stepErrorExists(AddVehicleStepsMapping[el]);

      const handleNavOptionClick = () => {
        if (!isCurrentStep && navOptionsEnabled && !isLoading) {
          setNavOptionsEnabled(false);
          setVisitedFormStep(step);
          if (el === AddVehicleSteps.PHOTOS && photos?.filter(it => it?.fileUrl)?.length) {
            nextForm(5);
          } else {
            nextForm(AddVehicleStepsMapping[el]);
          }
        }
        setTimeout(() => setNavOptionsEnabled(true), 300);
      };

      return (
        <>
          <div
            key={uuid()}
            onClick={handleNavOptionClick}
            className={`account-layout__nav-item add-vehicle__nav-item ${
              isCurrentStep ? 'active' : 'disabled'
            } ${isActive ? 'add-vehicle__nav-prev-item' : ''}`}
          >
            {AddVehicleStepsName[el]}
            {(isFormInitialized || !isAuctionEditing) && showError && (
              <img
                id={`nav-option-${el}`}
                width="24px"
                height="24px"
                src={errorIcon}
                alt="error icon"
              />
            )}
          </div>
          {(isFormInitialized || !isAuctionEditing) && showError && (
            <UncontrolledTooltip
              className="uncontrolled-tooltip"
              delay={0}
              target={`nav-option-${el}`}
            >
              {prepareErrorMessage(el)}
            </UncontrolledTooltip>
          )}
        </>
      );
    });
  }, [step, validationErrors, navOptionsEnabled, isLoading]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <AddNumberForm values={values} nextForm={nextForm} vehicleFound={vehicleFound} />
        );
      case 2:
        return vehicleFound ? (
          <ConfirmNumberForm values={values} nextForm={nextForm} prevForm={prevForm} />
        ) : (
          <VehicleNotFound values={values} nextForm={nextForm} prevForm={prevForm} />
        );
      case 3:
        return (
          <AddBasicCarDataForm
            values={values}
            nextForm={nextForm}
            prevForm={prevForm}
            form={form}
          />
        );
      case 4:
        return (
          <AddPhotoForm values={values} prevForm={prevForm} nextForm={nextForm} form={form} />
        );
      case 5:
        return (
          <AddPhotoSummaryPage
            values={values}
            currentForm={step}
            form={form}
            prevForm={() => {
              if (photos?.length > 0) {
                nextForm(3);
              } else {
                prevForm();
              }
            }}
            nextForm={nextForm}
          />
        );
      case 6:
        return (
          <AddVideoForm
            prevForm={() => {
              nextForm(photos?.length ? 5 : 4);
            }}
            nextForm={nextForm}
          />
        );
      case 7:
        return (
          <TyreTreadDepths
            form={form}
            prevForm={prevForm}
            values={values}
            nextForm={nextForm}
          />
        );
      case 8:
        return (
          <ServiceHistoryPage
            prevForm={prevForm}
            nextForm={nextForm}
            values={form?.values}
            updateParentForm={updateServiceHistory}
          />
        );
      case 9:
        return (
          <AddFeaturesForm
            values={values}
            nextForm={nextForm}
            prevForm={prevForm}
            form={form}
          />
        );
      case 10:
        return (
          <AddDescriptionPage
            prevForm={prevForm}
            generalValues={values}
            nextForm={nextForm}
            form={form}
            updateParentForm={updateVehicleDescription}
          />
        );
      case 11:
        return <LaunchVehicleForm prevForm={prevForm} nextForm={nextForm} />;
      default:
        return null;
    }
  };

  return (
    <AccountLayoutWrapper navOptions={navOptions()}>
      {renderStep()}
      <Prompt
        when={true}
        message={location => {
          if (step > 2 && step < 11 && location?.pathname !== '/') {
            return 'Are you sure you want to leave this page? Any unsaved changes will be lost. Please confirm to proceed.';
          }

          return true;
        }}
      />
    </AccountLayoutWrapper>
  );
};

AddVehicleForm.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default AddVehicleForm;
